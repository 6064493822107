<script setup>
const props = defineProps({
  viewType: {
    type: String,
    default: 'News',
  },
  color: {
    type: String,
    default: '',
  },
});

function getIcon(view) {
  switch (view) {
    case 'work_schedule':
      return 'id-card';
    case 'Booking':
      return 'key';
    case 'MealPlan':
      return 'utensils';
    case 'Activities':
      return 'running';
    case 'Galleries':
      return 'photo-video';
    case 'Meetings':
      return 'comments-alt';
    case 'News':
      return 'comment-alt-lines';
    default:
      return 'grey';
  }
}
</script>

<template>
  <div
    class="fixed w-80 right-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
  >
    <img
      :src="`/img/emptyWidgets/${viewType}.svg`"
      alt="No data"
      class="w-60 m-auto"
    />
    <div
      class="text-center mt-32 text-3xl font-semibold"
      :class="`text-${color}`"
    >
      <fa-icon
        class="text-4xl pb-4"
        :icon="['fas', getIcon(viewType) || 'clock']"
      />
      <p>{{ $t(`emptyStates.${viewType.toUpperCase()}`) }}</p>
    </div>
  </div>
</template>
