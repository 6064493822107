import store from '@store';
import axios from 'axios';

export default {
  async getDepartment(loginKey) {
    const response = await axios.get(
      `${import.meta.env.VITE_LOGIN_URL}/department/accesstoken`,
      {
        headers: {
          Authorization: loginKey,
        },
      }
    );

    const { accessToken, id } = response.data;

    if (!accessToken) {
      throw new Error('No data found for department with loginKey ', loginKey);
    }

    localStorage.setItem(
      'department_data',
      JSON.stringify({ accessToken, id })
    );

    store.dispatch('department/setDepartmentId', id);
  },
  getDepartmentData() {
    const departmentData = localStorage.getItem('department_data') || '';
    let parsedData;

    try {
      parsedData = JSON.parse(departmentData);
    } catch (_error) {
      parsedData = { id: undefined, accessToken: undefined };
    }

    return parsedData;
  },
  async authenticate() {
    let tempKey = sessionStorage.getItem('temp_login_key');

    // Handle case when session storage reads null value as string.
    if (tempKey === 'null') {
      tempKey = null;
    }

    const mainKey =
      new URLSearchParams(window.location.search).get('loginKey') ||
      sessionStorage.getItem('main_login_key');

    sessionStorage.setItem('main_login_key', mainKey);

    let loginKey = tempKey || mainKey;
    localStorage.setItem('login_key', loginKey);

    if (loginKey && loginKey.length) {
      await this.getDepartment(loginKey);
    } else {
      throw new Error('No loginKey provided');
    }
  },
};
