<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { get } from 'lodash';
import CoverView from '@views/shared/CoverView.vue';
import DateTimeBlock from '@components/shared/blocks/DateTimeBlock.vue';
import TitleBlock from '@components/shared/blocks/TitleBlock.vue';
import Tts from '@components/shared/Tts.vue';
import PeopleBlock from '@components/shared/blocks/PeopleBlock.vue';
import DescriptionBlock from '@components/shared/blocks/DescriptionBlock.vue';

const store = useStore();
const route = useRoute();

const booking = computed(
  () => store.getters['bookingResources/selectedBookingEntry']
);

const resources = computed(() => store.getters['bookingResources/all']);

const resource = computed(() => {
  return resources.value.find((resource) =>
    resource.bookings.find(
      (resourceBooking) => resourceBooking.id === booking.value.id
    )
  );
});

const moduleColor = computed(() => {
  return route?.meta?.color;
});

const imageUrl = computed(() => {
  return get(booking.value, 'coverImage.source', '/img/placeholder.png');
});

function showProfiles(profileType) {
  store.dispatch('general/setActiveOverlay', {
    name: 'participants-list',
    data: {
      title: `global.${profileType}`,
      profiles: booking.value.participants,
    },
  });
}
</script>

<template>
  <div class="booking">
    <cover-view
      v-if="booking.id"
      :image-url="imageUrl"
      :gradient="true"
      :module-color="moduleColor"
      :margin-top="60"
    >
      <div class="bookingContent tts-content pt-20">
        <tts entity-type="BOOKING" />

        <TitleBlock :text="resource?.title" />

        <DescriptionBlock :description="booking.description" />

        <DateTimeBlock :start="booking.start" :end="booking.end" />

        <PeopleBlock
          v-if="booking.participants.length"
          :module-color="moduleColor"
          :profiles="booking.participants"
          @show-people="showProfiles('participants')"
        />
      </div>
    </cover-view>
  </div>
</template>

<style lang="scss">
.booking {
  .bookingContent {
    &.participants {
      margin-top: 700px;
    }

    > *:not(.mediaIcons) {
      padding: 0 100px;
    }
  }

  .bookingActions {
    bottom: 70px;
    padding-right: 80px;
  }
}
</style>
