<script setup>
import { computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import i18n from '@i18n';
import { default as ButtonEl } from '@components/shared/Button.vue';
import { get, capitalize } from 'lodash';
import CoverView from '@views/shared/CoverView.vue';
import PinFlow from '@utils/pinFlow';
import ParticipantsBlock from '@components/shared/blocks/ParticipantsBlock.vue';
import DateTimeBlock from '@components/shared/blocks/DateTimeBlock.vue';
import DescriptionBlock from '@components/shared/blocks/DescriptionBlock.vue';
import InstitutionBlock from '@components/shared/blocks/InstitutionBlock.vue';
import BlocksWrapper from '@components/shared/blocks/BlocksWrapper.vue';
import BlockWrapper from '@components/shared/blocks/BlockWrapper.vue';

const store = useStore();
const route = useRoute();
const router = useRouter();

const allBookingResources = computed(
  () => store.getters['bookingResources/all']
);
const resource = computed(
  () => store.getters['bookingResources/selectedBookingResource']
);

const pinValidation = computed(() => store.getters['profiles/pinValidation']);
const opt = computed(() => store.getters['bookingResources/opt']);

const moduleColor = computed(() => {
  return route?.meta?.color;
});

const imageUrl = computed(() => {
  return get(resource.value, 'coverImage.source', '/img/placeholder.png');
});

onMounted(() => {
  window._paq.push(['trackEvent', 'BookingEntry', 'Viewed']);
});

watch(allBookingResources, (resources) => {
  const resource = resources.find(
    (resource) => resource.id === resource?.value?.id
  );

  if (!resource) return;
  store.dispatch('bookingResources/setSelectedBookingResource', resource);
});

watch(pinValidation, (pinData) => {
  if (!pinData.validPin) return;

  if (resource.value.slotted) {
    bookSlot();
  } else {
    startNewBooking();
  }
});

function showProfiles(profileType, index) {
  store.dispatch('general/setActiveOverlay', {
    name: 'participants-list',
    data: {
      title: `global.${profileType}`,
      profiles: resource.value.bookings[index].participants,
    },
  });
}

async function startNewBooking() {
  store.dispatch('bookingResources/setNewBooking', {
    resourceId: resource.value.id,
  });

  await store.dispatch('general/setActiveOverlay', {
    name: 'new-booking',
    data: {
      title: `${capitalize(i18n.global.t('booking.book'))} ${resource.value.title}`,
    },
  });
}

async function startNewSlottedBooking() {
  await store.dispatch('general/setActiveOverlay', {
    name: 'new-slotted-booking',
    data: {
      title: `${capitalize(i18n.global.t('booking.book'))} ${resource.value.title}`,
    },
  });
}

async function newBooking() {
  if (resource.value.slotted) {
    await store.dispatch('bookingResources/setSelectedSlottedWeek');
    startNewSlottedBooking();
    return;
  }

  const pinFlow = new PinFlow({
    entity: 'booking',
    type: 'create',
    title: resource.value.title,
    sharedDepartments: resource.value.departments,
  });

  switch (resource.value.authentication) {
    case 'NONE':
      startNewBooking();
      break;
    case 'PASSWORD':
      pinFlow.startCreateFlow('booking.toBook');
      break;
    case 'EMPLOYEE_ONLY':
      pinFlow.startCreateFlow('booking.toBook', true);
      break;
  }
}

async function bookSlot() {
  if (opt.value === 'in') {
    await store.dispatch('bookingResources/createNewSlottedBooking');
  } else {
    await store.dispatch('bookingResources/cancelSlottedBooking');
  }

  startNewSlottedBooking();
}

function goToBooking(booking) {
  store.dispatch('bookingResources/setSelectedBookingEntry', booking);
  router.push(`/bookings/booking/${booking.id}`);
}
</script>

<template>
  <div class="booking">
    <cover-view
      v-if="resource.id"
      :image-url="imageUrl"
      :gradient="true"
      :module-color="moduleColor"
      :margin-top="60"
    >
      <div class="bookingContent pt-20">
        <div class="largeListItemHeader pt-12 mb-12">
          {{ resource.title }}
        </div>

        <DescriptionBlock :description="resource.description" />

        <InstitutionBlock />

        <BlocksWrapper title="booking.existingBookings">
          <BlockWrapper
            v-for="(booking, index) in resource.bookings"
            :key="index"
            class="mb-8"
            @click="goToBooking(booking)"
          >
            <DescriptionBlock
              margin="mb-8"
              :description="booking.description"
            />

            <DateTimeBlock
              margin="mb-8"
              :start="booking.start"
              :end="booking.end"
            />

            <ParticipantsBlock
              margin="mb-8"
              v-if="booking.participants.length"
              :module-color="moduleColor"
              :profiles="booking.participants"
              :hideParticipants="true"
              icon=""
            />
          </BlockWrapper>
        </BlocksWrapper>
      </div>

      <div class="newBooking flex sticky w-fit">
        <ButtonEl
          text="booking.newBooking"
          icon="plus"
          background-color="success"
          text-color="white"
          class="mr-5 shadow-xsm"
          @click="newBooking"
        />
      </div>
    </cover-view>
  </div>
</template>

<style lang="scss">
.booking {
  .bookingContent {
    flex: 1;
    &.participants {
      margin-top: 700px;
    }

    > *:not(.mediaIcons) {
      padding: 0 100px;
    }
  }

  .newBooking {
    bottom: 40px;
    margin-right: 20px;
    align-self: flex-end;
  }
}
</style>
