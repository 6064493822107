<script setup>
import { useRoute } from 'vue-router';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  imageUrl: {
    type: String,
    default: '',
  },
});
</script>

<template>
  <div
    class="card-basic flex flex-col items-center justify-center relative p-4 border border-neutral-300 bg-neutral-100"
  >
    <img class="mb-4 rounded-full border border-neutral-500" v-if="props.imageUrl" :src="props.imageUrl" />
    <span class="h700 text-center">
      {{ $t(props.title) }}
    </span>
  </div>
</template>

<style lang="scss">
.card-basic {
  border-radius: 12px;
  width: 270px;
  height: 270px;

  img {
    width: 160px;
    height: 160px;
    object-fit: cover;
  }
}
</style>
