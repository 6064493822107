<script setup>
import ModuleNavBar from './ModuleNavBar.vue';
import IBGLogo from '@components/IBGLogo.vue';
import DisplayModeToggle from '../DisplayModeToggle.vue';
import IconButton from '@components/shared/IconButton.vue';
import DimmerButton from '@components/dimmer/DimmerButton.vue';
import DepartmentSwitcher from '@components/shared/DepartmentSwitcher.vue';
import CategorySelector from '@components/shared/CategorySelector.vue';
import { useStore } from 'vuex';
import { get } from 'lodash';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { isEmpty } from 'lodash';

const store = useStore();
const route = useRoute();
const router = useRouter();
const emit = defineEmits(['close-overlay']);

const activeContentView = computed(() => {
  return store.getters['general/activeContentView'];
});
const hasActiveOverlay = computed(() => {
  return !isEmpty(store.getters['general/activeOverlay']);
});

const departmentSettings = computed(() => {
  return store.getters['department/settings'];
});

const currentDepartment = computed(() => {
  return store.getters['department/current'];
});

const canCloseBookingCategoryContentView = computed(() => {
  return !showNewBookingView.value && route.path === '/bookings';
});

const categories = computed(() => {
  return store.getters['galleries/categories'];
});

const activeViewType = computed(() => store.getters['general/activeViewType']);

const showNewBookingView = computed(() => {
  return (
    route.meta.contentViews &&
    route.meta.contentViews.length > 2 &&
    !!route.meta.contentViews[2] &&
    activeContentView.value?.name !== 'new-booking'
  );
});

const useGlobalDepartmentSwitcher = computed(() => {
  const settingEnabled = get(
    departmentSettings.value,
    'screenConfig.departmentSwitcher',
    false
  );
  const hasTempKey = sessionStorage.getItem('temp_login_key');

  return settingEnabled || hasTempKey;
});

// Can toggle view if both list and grid is available.
const canToggleListView = computed(() => {
  const contentViews = route.meta.contentViews;
  return (
    contentViews &&
    contentViews.some((view) => view.name === 'list') &&
    contentViews.some((view) => view.name === 'grid')
  );
});

const closeOverlayOrBack = () => {
  hasActiveOverlay.value ? emit('close-overlay', true) : router.back();
};

// Sets the contentView to handle cardType & positioning and activeViewType to handle list or grid view.
async function handleContentAndViewChange(viewChangedTo) {
  // Get the full contentView object that has been switched to then set store accordingly.
  const contentView = route.meta.contentViews.find(
    (view) => view.name === viewChangedTo
  );

  if (contentView) {
    await store.dispatch('general/setActiveContentView', contentView);

    if (viewChangedTo === 'list' || viewChangedTo === 'grid') {
      await store.dispatch('general/setActiveViewType', viewChangedTo);
    }
  } else {
    console.error('Changed contentView to non supported value:', viewChangedTo);
  }
}
</script>

<template>
  <div
    v-if="
      categories?.length &&
      activeViewType === 'list' &&
      route.path === '/galleries'
    "
    class="left-0 right-0 px-8 categories rounded-t-3xl relative bg-white shadow-topSmall py-8 pl-8 pr-0 pb-16"
  >
    <CategorySelector />
  </div>
  <div
    class="w-full relative rounded-t-3xl border-[3px] border-neutral-400 bg-neutral-300 z-40"
  >
    <!-- Toolbar -->
    <div
      class="flex flex-row items-center justify-between w-full absolute left-0 right-0 transform -translate-y-1/2 px-8"
    >
      <!-- Left container -->
      <div class="flex flex-1 justify-start">
        <IconButton
          v-show="route.path !== '/'"
          :icon="['fas', 'arrow-left']"
          :title="$t('global.backButton')"
          @click="closeOverlayOrBack()"
          capitalizeTitle
        />
      </div>

      <!-- Logo -->
      <IBGLogo class="flex-none" />

      <!-- Right container -->
      <div class="flex flex-1 justify-end">
        <DisplayModeToggle
          v-if="canToggleListView"
          @view-changed="handleContentAndViewChange"
        />

        <dimmer-button v-if="route.path === '/'" />

        <IconButton
          v-if="route.path === '/bookings'"
          class="pl-3"
          :icon="['far', 'fa-xmark']"
          @click="router.push('/bookingsList')"
        />
      </div>
    </div>

    <!-- Footer overlay/background -->
    <div
      class="flex flex-col justify-center pt-12"
      :style="{ height: useGlobalDepartmentSwitcher ? '208px' : '250px' }"
    >
      <ModuleNavBar />
    </div>
    <DepartmentSwitcher
      v-if="useGlobalDepartmentSwitcher && currentDepartment.name"
    />
  </div>
</template>
