import {
  isToday,
  isBefore,
  isAfter,
  isTomorrow,
  isThisWeek,
  differenceInSeconds,
} from 'date-fns';
import { format } from '@utils/date-fns';
import { capitalize } from 'lodash';
import i18n from '@i18n';

export function isItemNow(startDate, endDate) {
  return (
    isAfter(new Date(), new Date(startDate)) &&
    isBefore(new Date(), new Date(endDate))
  );
}

export function isItemToday(dateString) {
  return isToday(new Date(dateString));
}

export function isItemThisWeek(dateString) {
  return isThisWeek(new Date(dateString));
}

export function getWeekday(dateString) {
  if (!dateString) return '';
  const date = new Date(dateString);
  return capitalize(format(date, 'EEEE'));
}

export function diffInSeconds(dateStringStart, dateStringEnd) {
  return differenceInSeconds(
    new Date(dateStringStart),
    new Date(dateStringEnd)
  );
}

export function formatTime(dateString) {
  return format(new Date(dateString), 'HH:mm');
}

export function formatDate(dateString) {
  return format(new Date(dateString), 'dd MMMM');
}

export function formatRepeat(repeat) {
  const matches = repeat.rrule.match(/(?<=FREQ=).*?(?=;)/);
  return matches.length ? matches[0] : false;
}

export function toUnix(dateString) {
  return Math.floor(new Date(dateString).getTime());
}

export function timeToUnix(timeString) {
  const normalizedTime = timeString.replace(/\./g, ':'); // allow for both HH.mm.ss and HH:mm:ss
  const [hours, minutes, seconds = 0] = normalizedTime.split(':');
  const now = new Date();
  now.setHours(hours, minutes, seconds, 0);
  return now.getTime();
}

export function stringToMinutes(str) {
  return str.split(':').reduce((h, m) => h * 60 + +m);
}

export function minutesToString(min) {
  return (
    Math.floor(min / 60) +
    ':' +
    (min % 60 === 0 ? '00' : min % 60)
  ).replace(/\b\d\b/, '0$&');
}

export function hexToRgbA(hex, alpha) {
  let color;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    color = hex.substring(1).split('');

    if (color.length === 3) {
      color = [color[0], color[0], color[1], color[1], color[2], color[2]];
    }

    color = '0x' + color.join('');
    const colorParts = [(color >> 16) & 255, (color >> 8) & 255, color & 255];

    return `rgba(${colorParts.join(',')}, ${alpha})`;
  }
}

export function getStartDay(
  dateString,
  onlyWeekday = false,
  showTimeOfDay = true
) {
  if (!dateString) return;

  // BE uses GMT+0. Create date object to get the correct timezone.
  const date = new Date(dateString);

  const timeOfDayAbbr = showTimeOfDay
    ? i18n.global.t('global.timeOfDayAbbr')
    : '';

  const formattedTime = showTimeOfDay ? format(date, 'HH:mm') : '';

  const timeOfDay = onlyWeekday ? '' : ` ${timeOfDayAbbr} ${formattedTime}`;

  if (isToday(date)) {
    return `${i18n.global.t('global.today')}${timeOfDay}`;
  }

  if (isTomorrow(date)) {
    return `${i18n.global.t('global.tomorrow')}${timeOfDay}`;
  }

  // Use EU week format by setting start day to monday.
  if (isThisWeek(date, { weekStartsOn: 1 })) {
    return `${format(date, 'EEEE')} ${timeOfDay}`;
  }

  if (onlyWeekday) {
    return format(date, 'EEEE');
  }

  return format(date, "EEEE d'.' MMMM");
}

export function getAATextColor(backgroundColor) {
  const color =
    backgroundColor.charAt(0) === '#'
      ? backgroundColor.substring(1, 7)
      : backgroundColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000' : '#fff';
}
