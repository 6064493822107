<script setup>
import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { getModuleData } from '@utils/modules';
import { useRouter } from 'vue-router';
import ModuleBaseView from '@components/moduleBaseView/ModuleBaseView.vue';
import { default as ButtonEl } from '@components/shared/Button.vue';

const store = useStore();
const router = useRouter();

onBeforeMount(() => {
  store.dispatch('bookingResources/getBookingResources', { useCache: true });
});

const bookings = computed(
  () => store.getters['bookingResources/resourceBookings']
);

const bookingSelected = (booking) => {
  store.dispatch('bookingResources/setSelectedBookingEntry', booking);
  router.push(`/bookings/booking/${booking.id}`);
};

const { colorHex, icon, emptyKey, routeName, entryRouterName } =
  getModuleData().BOOKING;
</script>

<template>
  <div>
    <ModuleBaseView
      :moduleName="emptyKey"
      :moduleData="bookings || []"
      :colorHex="colorHex"
      :icon="icon"
      :emptyKey="emptyKey"
      :routeName="routeName"
      :entryRouterName="entryRouterName"
      @entryItemClicked="bookingSelected($event)"
    />

    <ButtonEl
      class="fixed bottom-[400px] right-8"
      backgroundColor="success"
      icon="plus"
      text="booking.newBooking"
      @click="router.push('bookings')"
    />
  </div>
</template>
