import gql from 'graphql-tag';

export const journeyPlannerQuery = gql`
  query departmentTravelPlan($id: ID!) {
    externalServices {
      getTravelPlan(id: $id) {
        cls
        type
        name
        stop
        direction
        rtTime
        time
      }
    }
  }
`;
