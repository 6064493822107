<script setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { isEmpty } from 'lodash';
import { default as HeaderEl } from '@components/shared/Header.vue';
import { default as FooterEl } from '@components/footer/Footer.vue';
import Overlay from '@views/overlays/Overlay.vue';
import DimmerOverlay from '@components/dimmer/DimmerOverlay.vue';
import i18n from '@i18n';
import IdleWatcher from '@utils/idleWatcher.vue';
import auth from '@utils/auth';
import constants from '@utils/constants.js';

const store = useStore();
const route = useRoute();
const router = useRouter();

const showOverlay = ref(false);

const isLoading = computed(() => store.getters['general/isLoading']);
const activeOverlay = computed(() => store.getters['general/activeOverlay']);
const nextActiveOverlay = computed(
  () => store.getters['general/nextActiveOverlay']
);
const institutionLocale = computed(() => store.getters['institution/locale']);
const institutionId = computed(() => store.getters['institution/id']);
const departmentId = computed(() => store.getters['department/id']);
const isIdle = computed(() => store.getters['idleHandler/isIdle']);
const idleTimeout = computed(() => store.getters['department/idleTimeout']);

const onStartPage = computed(() => {
  return route.path === '/';
});

const overlayHasValue = computed(() => {
  return !isEmpty(activeOverlay.value);
});

watch(activeOverlay, (newValue) => {
  if (!isEmpty(newValue)) {
    showOverlay.value = true;
  }
});

watch(isIdle, async (newValue) => {
  const mainLoginKey = sessionStorage.getItem('main_login_key');
  const tempLoginKey = sessionStorage.getItem('temp_login_key');

  // If theres a difference we are currently not on the main department so switch back.
  if (tempLoginKey && mainLoginKey !== tempLoginKey) {
    await store.dispatch('department/switchDepartment');
    return;
  }

  store.dispatch('galleries/setSelectedCategory', '');

  if (route.path !== '/') {
    router.push('/');
    return;
  }

  if (newValue === true) {
    // force the widgets to re-render
    store.dispatch('general/setIdle');
  }
});

watch(route, async (newValue) => {
  nextTick(() => {
    closeOverlay(true);
    if (newValue.meta.scrollUp) {
      scrollToContent('scroll-up', 1200);
    } else {
      scrollToContent('route-wrapper', 150);
    }

    if (newValue.fullPath === '/') {
      store.dispatch('profiles/setActiveProfile', {});
    }
  });
});

onMounted(async () => {
  await auth.authenticate();

  try {
    await store.dispatch('institution/getInstitution', { useCache: true });
    await store.dispatch('department/getDepartment', { useCache: true });
    store.dispatch('general/setIdle');
    store.dispatch('general/setCurrentDate', new Date());
    store.dispatch('institution/getExternalConnections', { useCache: true });
    store.dispatch('profiles/getProfiles', { useCache: true });

    i18n.global.locale = institutionLocale.value;
  } catch (err) {
    throw new Error('Could not get institution/department data, retrying');
  }

  const DIMENSION_INSTITUTION = 1;
  const DIMENSION_DEPARTMENT = 2;

  _paq.push(['setCustomDimension', DIMENSION_INSTITUTION, institutionId.value]);
  _paq.push(['setCustomDimension', DIMENSION_DEPARTMENT, departmentId.value]);

  store.dispatch('general/setLoading', false);
});

async function closeOverlay(force = false) {
  store.commit('idleHandler/SET_IS_PAUSED', false); // Always start idle checks whenever overlay closes.
  if (force) {
    await store.dispatch('general/setActiveOverlay', {});
    await store.dispatch('general/setNextActiveOverlay', {});
    await store.dispatch('profiles/setPinValidation', {});
    await store.dispatch('general/setActivePinFlow', '');
  }

  const hasNextOverlay = !isEmpty(nextActiveOverlay.value);
  showOverlay.value = false;

  if (hasNextOverlay) {
    setTimeout(() => {
      store.dispatch('general/setActiveOverlay', nextActiveOverlay.value);
      showOverlay.value = true;
      store.dispatch('general/setNextActiveOverlay', {});
    }, constants.animationDuration); // wait for the transition to end before we change the store value
  }
}

function scrollToContent(className, xValue) {
  const selector = document.querySelector(`.${className}`);
  if (selector) selector.scrollTop = xValue;
}
</script>

<template>
  <div
    id="app"
    :class="{ isLoading, 'bg-neutral-100': onStartPage }"
    class="flex flex-col relative"
  >
    <HeaderEl />
    <div
      v-if="!isLoading"
      class="flex-1 relative overflow-y-scroll overflow-x-hidden route-wrapper"
    >
      <router-view :key="$route.path" class="bg-white" />
    </div>

    <FooterEl @close-overlay="closeOverlay" />

    <overlay
      :show="overlayHasValue && showOverlay"
      @close-overlay="closeOverlay"
    />
    <dimmer-overlay v-if="onStartPage" />
    <idle-watcher v-if="idleTimeout > 0" />
  </div>
</template>

<style lang="scss">
.tick-credits {
  display: none !important;
  opacity: 0 !important;
}

#app {
  width: 1080px;
  height: 1920px;
  margin: auto;
  background: 'white';

  .content-views {
    transition: opacity 500ms;
  }

  .route-wrapper {
    .contentArea {
      animation-duration: 1.3s;

      .largeListItemHeader {
        border-radius: 25px 25px 0 0;
      }
    }

    &.scroll-up .contentArea {
      margin-top: 1200px;
    }
  }
}
</style>
