import { createRouter, createWebHistory } from 'vue-router';

import StartPage from '@views/startPage/StartPage.vue';
import WorkSchedule from '@views/workSchedule/WorkSchedule.vue';
import SimpleWorkSchedule from '@views/workSchedule/SimpleWorkSchedule.vue';
import BookingsModule from '@views/booking/BookingsModule.vue';
import BookingsList from '@views/booking/BookingsList.vue';
import BookingEntry from '@views/booking/BookingEntry.vue';
import BookingResourceEntry from '@views/booking/BookingResourceEntry.vue';
import MealPlanModule from '@views/meals/MealPlanModule.vue';
import MealEntry from '@views/meals/MealEntry.vue';
import ActivityEntry from '@views/activities/ActivityEntry.vue';
import GalleriesModule from '@views/galleries/GalleriesModule.vue';
import GalleryEntry from '@views/galleries/GalleryEntry.vue';
import MeetingsModule from '@views/meetings/MeetingsModule.vue';
import MeetingEntry from '@views/meetings/MeetingEntry.vue';
import NewsModule from '@views/news/NewsModule.vue';
import NewsEntry from '@views/news/NewsEntry.vue';
import OurHome from '@views/ourHome/OurHome.vue';
import ProfileEntry from '@views/ourHome/ProfileEntry.vue';
import ActivitiesModule from '@views/activities/ActivitiesModule.vue';
import ExternalActivitiesModule from '@views/activities/ExternalActivitiesModule.vue';
import { getModuleData } from '@utils/modules';

const moduleData = getModuleData();

const routes = [
  {
    path: '/',
    name: 'Start',
    component: StartPage,
  },
  {
    path: '/workSchedule',
    name: 'WORK_SCHEDULE',
    component: WorkSchedule,
    meta: {
      color: moduleData.WORK_SCHEDULE.colorId,
      icon: moduleData.WORK_SCHEDULE.icon,
      scrollUp: false,
    },
  },
  {
    path: '/simpleWorkSchedule',
    name: 'SIMPLE_WORK_SCHEDULE',
    component: SimpleWorkSchedule,
    meta: {
      color: moduleData.SIMPLE_WORK_SCHEDULE.colorId,
      icon: moduleData.WORK_SCHEDULE.icon,
      scrollUp: true,
    },
  },
  {
    path: '/bookings',
    name: 'BOOKING',
    component: BookingsModule,
    meta: {
      color: moduleData.BOOKING_RESOURCES.colorId,
      icon: moduleData.BOOKING_RESOURCES.icon,
      scrollUp: true,
      contentViews: [
        {
          name: 'new-booking',
          icon: 'calendar-plus',
          cardType: 'square',
          position: 'right',
        },
      ],
    },
  },
  {
    path: '/bookings/booking/:id',
    name: 'BOOKING_ENTRY',
    component: BookingEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.BOOKING.colorId,
      scrollUp: false,
    },
  },
  {
    path: '/bookingsList',
    name: 'BOOKINGS_LIST',
    component: BookingsList,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.BOOKING.colorId,
      icon: moduleData.BOOKING.icon,
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center',
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center',
        },
      ],
    },
  },
  {
    path: '/bookings/resource/:id',
    name: 'BOOKING_RESOURCE_ENTRY',
    component: BookingResourceEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.BOOKING.colorId,
      scrollUp: false,
    },
  },
  {
    path: '/ourHome',
    name: 'OUR_HOME',
    component: OurHome,
    meta: {
      color: moduleData.OUR_HOME.colorId,
      icon: moduleData.OUR_HOME.icon,
      scrollUp: false,
    },
  },
  {
    path: '/ourHome/:id',
    name: 'PROFILE',
    component: ProfileEntry,
    meta: {
      color: moduleData.OUR_HOME.colorId,
      subView: true,
      hideInModuleList: true,
      scrollUp: false,
    },
  },
  {
    path: '/mealPlan',
    name: 'MEAL_PLAN',
    component: MealPlanModule,
    meta: {
      color: moduleData.MEAL_PLAN.colorId,
      icon: moduleData.MEAL_PLAN.icon,
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center',
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center',
        },
      ],
    },
  },
  {
    path: '/mealPlan/:id',
    name: 'MEAL_PLAN_ENTRY',
    component: MealEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.MEAL_PLAN.colorId,
      scrollUp: false,
    },
  },
  {
    path: '/activities',
    name: 'ACTIVITIES',
    component: ActivitiesModule,
    meta: {
      color: moduleData.ACTIVITIES.colorId,
      icon: moduleData.ACTIVITIES.icon,
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center',
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center',
        },
      ],
    },
  },
  {
    path: '/activities/:id',
    name: 'ACTIVITIES_ENTRY',
    component: ActivityEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.ACTIVITIES.colorId,
      scrollUp: false,
    },
  },
  {
    path: '/externalActivities',
    name: 'EXTERNAL_ACTIVITIES',
    component: ExternalActivitiesModule,
    meta: {
      isExternal: true,
      connectionIndex: 0,
      color: moduleData.EXTERNAL_ACTIVITIES.colorId,
      icon: moduleData.EXTERNAL_ACTIVITIES.icon,
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center',
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center',
        },
      ],
    },
  },
  {
    path: '/externalActivities/:id',
    name: 'EXTERNAL_ACTIVITIES_ENTRY',
    component: ActivityEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.EXTERNAL_ACTIVITIES.colorId,
      scrollUp: false,
    },
  },
  {
    path: '/galleries',
    name: 'GALLERY',
    component: GalleriesModule,
    meta: {
      color: moduleData.GALLERY.colorId,
      icon: moduleData.GALLERY.icon,
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center',
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center',
        },
      ],
    },
  },
  {
    path: '/galleries/:id',
    name: 'GALLERY_ENTRY',
    component: GalleryEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.GALLERY.colorId,
      scrollUp: false,
    },
  },
  {
    path: '/meetings',
    name: 'MEETINGS',
    component: MeetingsModule,
    meta: {
      color: moduleData.MEETINGS.colorId,
      icon: moduleData.MEETINGS.icon,
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center',
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center',
        },
      ],
    },
  },
  {
    path: '/meetings/:id',
    name: 'MEETING_ENTRY',
    component: MeetingEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.MEETINGS.colorId,
      scrollUp: false,
    },
  },
  {
    path: '/news',
    name: 'NEWS',
    component: NewsModule,
    meta: {
      color: moduleData.NEWS.colorId,
      icon: moduleData.NEWS.icon,
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center',
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center',
        },
      ],
    },
  },
  {
    path: '/news/:id',
    name: 'NEWS_ENTRY',
    component: NewsEntry,
    meta: {
      subView: true,
      hideInModuleList: true,
      color: moduleData.NEWS.colorId,
      scrollUp: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
