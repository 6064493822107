export default {
  'schedule-dark-default': '#37A2A7',
  'schedule-dark-aa': '#3CADB2',
  'schedule-light': '#F5FAFB',
  'schedule-light-default': '#186571',
  'laterSchedule-light-default': '#008C93',
  'schedule-light-aa': '#2B8084',
  'schedule-light-aaa': '#1E5F62',
  'booking-dark-default': '#ADC301',
  'booking-dark-aa': '#ADC301',
  'booking-dark-aaa': '#B6CE00',
  'booking-light-default': '#ADC301',
  'booking-light-aa': '#6F7D00',
  'booking-light-aaa': '#535D00',
  'about-dark-default': '#58A202',
  'about-dark-aa': '#60B200',
  'about-dark-aaa': '#84D524',
  'about-light-default': '#58A202',
  'about-light-aa': '#468201',
  'about-light-aaa': '#376401',
  'meals-dark-default': '#0086E7',
  'meals-dark-aa': '#199FFF',
  'meals-dark-aaa': '#7CC8FF',
  'meals-light-default': '#0080DC',
  'meals-light-aa': '#0079CF',
  'meals-light-aaa': '#005A9B',
  'activities-dark-default': '#9848FF',
  'activities-dark-aa': '#B881FF',
  'activities-dark-aaa': '#D4B2FF',
  'activities-light-default': '#591EA3',
  'externalActivities-light-default': '#6814D4',
  'activities-light-aa': '#591EA3',
  'activities-light-aaa': '#591EA3',
  'galleries-dark-default': '#D565E0',
  'galleries-dark-aa': '#F273FF',
  'galleries-dark-aaa': '#F7A1FF',
  'galleries-light-default': '#622A67',
  'galleries-light-aa': '#622A67',
  'galleries-light-aaa': '#622A67',
  'meetings-dark-default': '#EA3890',
  'meetings-dark-aa': '#FF5CAD',
  'meetings-dark-aaa': '#FFA5D1',
  'meetings-light-default': '#932C5F',
  'meetings-light-aa': '#932C5F',
  'meetings-light-aaa': '#932C5F',
  'news-dark-default': '#F64800',
  'news-dark-aa': '#FF6B2E',
  'news-dark-aaa': '#FFAC89',
  'news-light-default': '#EA4400',
  'news-light-aa': '#D83F00',
  'news-light-aaa': '#A22F00',
  'calendar-dark-default': '#FF765B',
  'calendar-dark-aa': '#FF765B',
  'calendar-dark-aaa': '#FFAA9A',
  'calendar-light-default': '#FF765B',
  'calendar-light-aa': '#BD5743',
  'calendar-light-aaa': '#904132',
  'journeyPlanner-dark-default': '#363636',
  'journeyPlanner-red': '#F93D45',
  'journeyPlanner-dark-red': '#B41730',
  'journeyPlanner-yellow': '#FFBE32',
  'weather-light-default': '#363636',

  // generic colors
  charcoal: '#323232',
  'charcoal-light': '#989898',
  'charcoal-xlight': '#E0E0E0',
  'charcoal-xxlight': 'rgba(50, 50, 50, 0.1)',
  'charcoal-opacity': 'rgba(50, 50, 50, 0.9)',
  'black-025': 'rgba(0, 0, 0, 0.25)',
  white: '#ffffff',
  'transparent-white': '#FFFFFF1A',
  success: '#69C200',
  error: '#E20000',
  orange: '#FF765B',
  warning: '#E2B100',
  darkOcean600: '#00434E',
  darkOcean100: '#DAF1F4',
  'green-400': '#118531',
  'red-400': '#C92A15',
  'yellow-300': '#D9A90E',

  // colors based on widget type
  ACTIVITIES: '#591EA3',
  BOOKING: '#6F7E00',
  EXTERNAL_ACTIVITIES: '#6814D4',
  GALLERY: '#C24C1E',
  JOURNEY_PLANNER: '#696969',
  MEAL_PLAN: '#0079D0',
  MEETINGS: '#D13884',
  NEWS: '#A76800',
  OUR_HOME: '#468202',
  SIMPLE_WORK_SCHEDULE: '#186571',
  WAYFINDER: '#000',
  WEATHER: '#696969',
  WORK_SCHEDULE: '#186571',
  LATER_WORK_SCHEDULE: '#008C93',

  // ***********************************************************
  // ******************** New design colors ********************
  // ***********************************************************
  // Using weight as a scale for darkness.
  neutral: {
    0: '#FFFFFF',
    50: '#F9F9F9',
    100: '#F3F3F3',
    200: '#EEEEEE',
    300: '#DFDFDF',
    '300A': '#FFFFFF99',
    400: '#D1D1D1',
    500: '#A4A4A4',
    600: '#878787',
    700: '#696969',
    800: '#3F3F3F',
    900: '#202020',
    1000: '#151515',
  },
  darkOcean: {
    50: '#87C7CA',
    100: '#5FB5B9',
    200: '#37A2A7',
    300: '#2C8286',
    400: '#1D7076',
    500: '#186571',
    600: '#216164',
  },
  macaron: {
    100: '#CDE8E9',
  },
  green: {
    200: '#7FD698',
    400: '#118531',
  },
  cancelled: '#C92A15',
  DarkNeutral400A: '#15151566',
};
