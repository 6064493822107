<script setup>
import { format } from '@utils/date-fns';
import { capitalize } from 'lodash';
import { computed } from 'vue';

const props = defineProps({
  start: {
    type: String,
    default: '',
  },
  end: {
    type: String,
    default: '',
  },
  margin: {
    type: String,
    default: 'mb-14',
  },
});

const formattedStartDate = computed(() => {
  return capitalize(format(new Date(props.start), 'EEEE d. MMMM'));
});

const formattedEndDate = computed(() => {
  return capitalize(format(new Date(props.end), 'EEEE d. MMMM'));
});

const formattedStartTime = computed(() => {
  return format(new Date(props.start), 'HH:mm');
});

const formattedEndTime = computed(() => {
  return format(new Date(props.end), 'HH:mm');
});
</script>

<template>
  <div class="relative content flex items-center w-full" :class="margin">
    <fa-icon
      :class="{ 'mt-2': formattedStartDate !== formattedEndDate }"
      class="mr-6 self-start"
      :icon="['fal', 'clock']"
      :aria-label="$t('global.date')"
    />

    <div
      v-if="formattedStartDate !== formattedEndDate"
      class="flex flex-col"
      :aria-label="`${formattedStartDate} ${formattedStartTime} ${$t('global.toDate')} ${formattedEndDate} ${formattedEndTime}`"
    >
      <span>
        {{ formattedStartDate }} -
        <span class="font-bold">{{ formattedStartTime }}</span>
      </span>

      <span>
        {{ formattedEndDate }} -
        <span class="font-bold">{{ formattedEndTime }}</span>
      </span>
    </div>

    <div v-else class="flex flex-col">
      <span :aria-label="formattedStartDate">{{ formattedStartDate }}</span>
      <span
        class="font-bold"
        :aria-label="`${formattedStartTime} - ${formattedEndTime}`"
        >{{ formattedStartTime }} - {{ formattedEndTime }}</span
      >
    </div>
  </div>
</template>
