import { apolloClient } from '../../utils/apollo';
import { institutionQuery } from '@graphql/queries/institution.js';
import { appConnectionsScreenQuery } from '@graphql/queries/appConnectionsScreen.js';

const state = {
  id: null,
  name: null,
  locale: null,
  logo: {},
  departments: [], // All departments under the institution that should be visible to each other, e.g in pinFlows.
  availableDepartments: [], // Departments that are able to be switched to, via the departmentSwitcher.
  externalConnections: [],
  settings: {},
  didLoadExternalConnections: false,
};

const mutations = {
  SET_INSTITUTION(state, institution) {
    Object.assign(state, institution);
  },
  SET_DID_LOAD_EXT_CONNECTIONS(state, didLoadExternalConnections) {
    state.didLoadExternalConnections = didLoadExternalConnections;
  },
  SET_EXTERNAL_CONNECTIONS(state, externalConnections) {
    state.externalConnections = externalConnections;
  },
};

const actions = {
  async getInstitution({ commit, _, rootState }) {
    await apolloClient
      .query({
        query: institutionQuery,
      })
      .then((response) => {
        const currentDepartmentId = rootState.department.id;

        // TODO when BE passes loginKey for requesting department https://proreact.atlassian.net/browse/IBG-6214,
        // Use this task to change the filtering logic: https://proreact.atlassian.net/browse/IBG-6215.
        // But for now exclude on empty string '', as the current department loginKey is null while we still want to include it.
        const availableDepartments = response.data.institution.departments
          .filter(
            ({ settings, loginKey, employeeOnly }) =>
              settings.screenConfig.departmentSwitcher &&
              !employeeOnly &&
              loginKey !== ''
          )
          .sort(
            (a, b) =>
              (b.id === currentDepartmentId) - (a.id === currentDepartmentId)
          );

        commit('SET_INSTITUTION', {
          ...response.data.institution,
          availableDepartments,
        });
      })
      .catch((error) => {
        throw new Error('Error getting institution', error);
      });
  },
  async getExternalConnections({ commit, dispatch }) {
    await apolloClient
      .query({
        query: appConnectionsScreenQuery,
      })
      .then((response) => {
        commit('SET_EXTERNAL_CONNECTIONS', response.data.appConnectionsScreen);
      })
      .catch((error) => {
        console.error('Error getting external connections', error);
      })
      .finally(() => {
        commit('SET_DID_LOAD_EXT_CONNECTIONS', true);
        dispatch('department/checkIfValidExternalModule', null, {
          root: true,
        });
      });
  },
};

const getters = {
  id: (state) => state.id,
  name: (state) => state.name,
  locale: (state) => state.locale,
  departments: (state) => state.departments,
  availableDepartments: (state) => state.availableDepartments,
  externalConnections: (state) => state.externalConnections,
  logo: (state) => state.logo,
  settings: (state) => state.settings,
  didLoadExternalConnections: (state) => state.didLoadExternalConnections,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
